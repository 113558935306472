import Api from '@/services/api.service'

const actions = {
  deleteFile({ commit }, id) {
    return Api().delete(`/finder/delete/${id}`)
  },
  getFiles({ commit }, params) {
    return Api().get(`/finder?root=${params.root}&path=${params.path}`)
  },
  createFolder({ commit }, payload) {
    return Api().post(`/finder/create/${payload.name}?root=${payload.root}&path=${payload.path}`)
  },
  removeFiles({ commit }, payload) {
    return Api().post(`/finder/remove`, payload)
  },
  renameFile({ commit }, payload) {
    return Api().post(`/finder/rename`, payload)
  },
}

export default {
  namespaced: true,
  actions,
}
