<template>
  <div class="uk-offcanvas-content">
    <Header />
    <!--<Sidebar />-->
    <div id="content" data-uk-height-viewport>
      <router-view />
    </div>
    <div id="offcanvas-nav" data-uk-offcanvas="flip: true; overlay: true">
      <div
        class="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide"
      >
        <button
          class="uk-offcanvas-close uk-close uk-icon"
          type="button"
          data-uk-close
        ></button>
        <ul class="uk-nav uk-nav-default">
          <router-link tag="li" :to="{ name: 'dashboard' }" exact @click.native="toggleMenu">
          <a> <span data-uk-icon="icon: home"></span> Dashboard </a>
        </router-link>
        <router-link tag="li" :to="{ name: 'customer' }" @click.native="toggleMenu">
          <a> <span data-uk-icon="icon: user"></span> Stammdaten </a>
        </router-link>
        <router-link tag="li" :to="{ name: 'technician' }" @click.native="toggleMenu">
          <a> <span data-uk-icon="icon: user"></span> Messtechniker </a>
        </router-link>
        <router-link tag="li" :to="{ name: 'project' }" @click.native="toggleMenu">
          <a> <span class="uk-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 27.789 22.215">
            <g id="Layer_39" data-name="Layer 39" transform="translate(-1 -4)">
              <path id="Pfad_1" data-name="Pfad 1" d="M3.777,26.215A2.777,2.777,0,0,1,1,23.438V6.777A2.777,2.777,0,0,1,3.777,4h6.479a.926.926,0,0,1,.713.333l2.8,3.369H25.066a.926.926,0,0,1,0,1.851H13.338a.926.926,0,0,1-.7-.333L9.821,5.851H3.777a.926.926,0,0,0-.926.926V23.438a.926.926,0,0,0,.926.926.926.926,0,1,1,0,1.851Z" transform="translate(0)" fill="currentcolor"/>
              <path id="Pfad_2" data-name="Pfad 2" d="M23.714,26.81H3.924A.926.926,0,0,1,3,25.69L5.775,12.731A.926.926,0,0,1,6.7,12H27.99a.926.926,0,0,1,.926,1.12l-2.481,11.5a2.777,2.777,0,0,1-2.721,2.194ZM5.072,24.959H23.714a.926.926,0,0,0,.926-.731l2.2-10.376H7.4Z" transform="translate(-0.147 -0.595)" fill="currentcolor"/>
            </g>
          </svg>  
          </span> Projekte </a>
        </router-link>
        <router-link tag="li" :to="{ name: 'media' }" @click.native="toggleMenu">
          <a> 
            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd" />
            </svg> Medien
          </a>
        </router-link>
        </ul>
        <div class="uk-flex uk-flex-center uk-margin-large-top">
          <a
            href="#"
            class="uk-icon-link uk-margin-small-right"
            @click.prevent="logout()"
            uk-icon="sign-out"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import Sidebar from '@/components/Sidebar'
import Header from '@/components/Header'
import { mapActions } from 'vuex'

export default {
  components: {
    Header,
    //Sidebar,
  },
  beforeCreate: function() {
    document.body.className = ''
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    toggleMenu() {
      this.$uikit.offcanvas('#offcanvas-nav').hide()
    }
  },
}
</script>
