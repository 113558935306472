import auth from '@/modules/auth/routes'
import dashboard from '@/modules/dashboard/routes'
import customer from '@/modules/customer/routes'
import technician from '@/modules/technician/routes'
import project from '@/modules/project/routes'
import users from '@/modules/users/routes'
import insurance from '@/modules/insurance/routes'
import media from '@/modules/media/routes'
export default [
    ...auth,
    ...dashboard,
    ...customer, 
    ...technician, 
    ...project, 
    ...users, 
    ...insurance,
    ...media, 
]
