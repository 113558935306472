export default [
  {
    path: '/technician',
    name: 'technician',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "technician" */ '../views/Index.vue')
  },
  {
    path: '/technician/edit/:id?',
    name: 'technician-edit',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "technician-edit" */ '../views/Edit.vue')
  }
]
