<template>
  <div id="app">
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
import DefaultLayout from './layout/Default'
import LoginLayout from '@/layout/Login.vue'
export default {
  name: 'App',
  components: {
    DefaultLayout,
    LoginLayout
  },
  mounted() {
    this.$store.dispatch('auth/fetchUser', this)
  },
  computed: {
    layout() {
      return this.$store.getters.layout
    }
  }
}
</script>
<style lang="scss">
  @import "@/assets/scss/app.scss";

</style>
