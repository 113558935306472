<template>
  <header id="top-head" class="uk-position-fixed">
    <div class="uk-container uk-container-expand">
      <nav class="uk-navbar" data-uk-navbar="mode:click; duration: 250">
        <div class="uk-navbar-left">
          <div class="uk-h4 uk-margin-remove uk-hidden@m">Gutachten</div>
        </div>
        <div class="uk-navbar-right">
          <ul class="uk-navbar-nav">
            <li class="uk-visible@m">
              <a
                href="#"
                data-uk-icon="icon: sign-out"
                title="Abmelden"
                data-uk-tooltip
                @click.prevent="logout"
              ></a>
            </li>
            <li class="uk-hidden@m">
              <a
                class="uk-navbar-toggle"
                data-uk-toggle
                data-uk-navbar-toggle-icon
                href="#offcanvas-nav"
                title="Offcanvas"
                data-uk-tooltip
              ></a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      logout: "auth/logout"
    })
  }
};
</script>
