import Vue from 'vue'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import App from './App.vue'
import router from './routes'
import store from './store'
import localforage from 'localforage'
import './registerServiceWorker'

localforage.config({
  driver: localforage.LOCALSTORAGE,
  storeName: 'ansolution-report'
})

import ability from '@/config/ability';
import { abilitiesPlugin } from '@casl/vue';

Vue.use(abilitiesPlugin, ability);

Vue.config.productionTip = false

UIkit.use(Icons)
Vue.prototype.$uikit = UIkit

import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);

import VueCurrencyInput from 'vue-currency-input'
const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'EUR'}
}
Vue.use(VueCurrencyInput, pluginOptions)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import './assets/css/select.css';

import OpenIndicator from '@/components/select/OpenIndicator.vue'
import Deselect from '@/components/select/Deselect.vue'

vSelect.props.components.default = () => ({
    OpenIndicator: OpenIndicator,
    Deselect: Deselect
  });

Vue.component('v-select', vSelect)

import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
Vue.prototype.$notify = iziToast

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

