import { isEmpty } from 'lodash'
import localforage from 'localforage'
import UserService from '@/services/user.service'
import { defineRulesFor}  from '@/config/ability'
import Router from '@/routes'

export const login = ({ dispatch }, { payload, context }) => {
  return UserService.login(payload)
    .then(response => {
      dispatch('setToken', response.data.token).then(() => {
        dispatch('fetchUser', context)
      })
    })
    .catch(error => {
      context.errors = error.response.data
    })
}

export const fetchUser = ({ commit }, context) => {
  return UserService.me().then(response => {
    commit('setAuthenticated', true)
    commit('setUserData', response.data)
    commit('SET_LAYOUT', 'default-layout', { root: true })
    context.$ability.update(defineRulesFor(response.data))
  })
}

export const logout = ({ dispatch }) => {
  //return UserService.logout().then(() => {
  dispatch('clearAuth')
  //});
}

export const setToken = ({ commit, dispatch }, token) => {
  if (isEmpty(token)) {
    return dispatch('checkTokenExists').then(() => {})
  }
  commit('setToken', token)
}

export const checkTokenExists = () => {
  return localforage.getItem('authtoken').then(token => {
    if (isEmpty(token)) {
      return Promise.reject('NO_STORAGE_TOKEN')
    }

    return Promise.resolve(token)
  })
}

export const clearAuth = ({ commit }) => {
  commit('setAuthenticated', false)
  commit('setUserData', null)
  commit('setToken', null)
  Router.push({ path: '/login' })
}
