import Api from '@/services/api.service'

const state = () => ({
  config: {},
  statuses: []
})

const getters = {
  config (state) {
    return state.config
  },
  statuses (state) {
    return state.statuses
  },
}

const mutations = {
  SET_CONFIG(state, config) {
		state.config = config
  },
}

const actions = {
  async getConfig({ commit }) {
    let config = await Api().get(`invoice/config`)
    commit('SET_CONFIG', config.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
