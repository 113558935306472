export default [
  {
    path: '/insurance',
    name: 'insurance',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "insurance" */ '../views/Index.vue')
  },
  {
    path: '/insurance/edit/:id?',
    name: 'insurance-edit',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "insurance-edit" */ '../views/Edit.vue')
  }
]
