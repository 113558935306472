import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import routes from './modules/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'uk-active',
  linkExactActiveClass: '',
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.needsAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    store.dispatch('auth/checkTokenExists').then(() => {
      next()
    }).catch(() => {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    })
  } else {
    next()
  }
})
export default router
