import axios from 'axios'
import Router from '../routes'
import localforage from 'localforage'
//import store from '../store'
import Configuration from '@/configuration';

const $axios = axios.create({
  baseURL: Configuration.value('apiUrl')//process.env.VUE_APP_API_URL
})

export default () => {
  $axios.interceptors.request.use(request => {
    request.headers.common['Content-Type'] = 'application/json'
    request.headers.common['X-AUTH-TOKEN'] = Configuration.value('token')//process.env.VUE_APP_TENANT
    request.credentials = 'same-origin'
    return localforage.getItem('authtoken').then((token) => {
      request.headers.common.Authorization = token
      return request
    }).catch(() => {
      return request
    })
   //return request
  })

  $axios.interceptors.response.use(undefined, (err) => {
    return new Promise(() => {
      if (err.response.status === 403 || err.response.status === 401) {
        Router.push({ path: '/login' }).catch(() => {})
      }
      throw err
    })
  })

  return $axios
}
