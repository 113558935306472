export default [
  {
    path: '/project',
    name: 'project',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "project" */ '../views/Index.vue')
  },
  {
    path: '/project/edit/:id?',
    name: 'project-edit',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "project-edit" */ '../views/Edit.vue')
  }
]
