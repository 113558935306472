export default [
  {
    path: '/users',
    name: 'users',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/Index.vue')
  }
]
