<template>
<div class="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
  <div class="uk-width-1-1@m">
    <div class="uk-margin uk-width-large uk-margin-auto uk-card uk-card-default uk-card-body uk-box-shadow-large">
      <h3 class="uk-card-title uk-text-center">Login</h3>
      <form @submit.prevent="submit">
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span class="uk-form-icon" uk-icon="icon: user"></span>
            <input class="uk-input uk-form-large" type="text" placeholder="Benutzername" v-model="username">
          </div>
        </div>
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span class="uk-form-icon" uk-icon="icon: lock"></span>
            <input class="uk-input uk-form-large" type="password" placeholder="Passwort" v-model="password">	
          </div>
        </div>
        <div class="uk-margin">
          <button class="uk-button uk-button-primary uk-button-large uk-width-1-1" type="submit" :disabled="sending">Anmelden</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>
<script>
import { mapActions, } from 'vuex'
export default {
  data() {
    return {
      username: null,
      password: null,
      sending: false,
      errors: [],
      error: false
    }
  },
  mounted() {
    this.$store.commit('SET_LAYOUT', 'login-layout')
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),

    async submit() {
      this.sending = true
      let form = new FormData()
      form.append('email', this.username)
      form.append('password', this.password)
      try {
        await this.login({
          payload: form,
          context: this
        })
        this.sending = false
        if (this.$route.query.redirect) {
          this.$router.replace(this.$route.query.redirect)
          return
        }

        this.$router.replace({ name: 'project' }).catch(() => {})
      } catch (error) {
        console.log(error);
        this.sending = false
      }
    }
  }
}
</script>
