<template>
    <div class="uk-section uk-section-muted uk-flex uk-flex-middle uk-animation-fade" uk-height-viewport>
	<div class="uk-width-1-1">
		<div class="uk-container">
            <router-view></router-view>
		</div>
	</div>
</div>
</template>
<script>
export default {
    
}
</script>