export default [
  {
    path: '/customer',
    name: 'customer',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "customer" */ '../views/Index.vue')
  },
  {
    path: '/customer/edit/:id?',
    name: 'customer-edit',
    meta: {
      needsAuth: true
    },
    component: () => import(/* webpackChunkName: "customer-edit" */ '../views/Edit.vue')
  }
]
