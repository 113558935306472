import { Ability, AbilityBuilder } from '@casl/ability'

export const ability = new Ability()

export function defineRulesFor(user) {
  const { can, cannot, rules } = AbilityBuilder.extract()
  user.roles.forEach(role => {
    switch (role) {
      case 'ROLE_ADMIN':
        can('manage', 'all')
        break
      case 'ROLE_EMPLOYEE':
        can('create', 'projects')
        can('view', 'projects')
        can('edit', 'projects')
        can('send', 'projects')
        cannot('delete', 'projects')
        can('print', 'projects')
        can('manage', 'technician')
        can('manage', 'customers')
        can('manage', 'insurance')
        can('edit', 'project:damage-recording')
        can('edit', 'project:insurance')
        can('edit', 'project:power-consumption')
        break
      case 'ROLE_EXTERNAL':
        can('view', 'projects')
        can('edit', 'projects')
        break
    }
  })

  return rules
}
