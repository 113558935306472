import Api from './auth.service'

export default {
  login (payload) {
    return Api().post('/login_check', payload)
  },

  logout () {
    return Api().post('/logout')
  },

  me () {
    return Api().get('/user')
  },

  getList() {
    return Api().get('/user/list')
  },
  store(payload) {
    return Api().put(`/user`, payload)
  },
  create(payload) {
    return Api().post(`/user`, payload)
  },
  update(payload) {
    return Api().put(`/user/update`, payload)
  },
}
