import Vue from 'vue'
import Vuex from 'vuex'
import auth from './../modules/auth/store'
import media from './../modules/media/store'
import invoice from './../modules/invoice/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    media,
    invoice
  },
  state: {
    layout: 'default-layout',
    tenant: {},
  },
  mutations: {
    SET_LAYOUT(state, layout) {
      state.layout = layout
    }
  },
  getters: {
    layout(state) {
      return state.layout
    },
    tenant(state) {
      return state.tenant
    }
  },
  actions: {},
})
